@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variables.scss';

.partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  min-height: 626px;
  margin: 0 auto;
  background-color: $default-background-color;

  @include respond-to(768px) {
    min-height: fit-content;
  }

  @include respond-to(768px) {
    background-color: $default-text-white-color;
  }
}

.partners_title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners_title__top {
  @include lightText(30px, 42px);
  text-align: center;

  @include respond-to(512px) {
    @include lightText(19px, 28px);
  }
}

.partners_title__bottom {
  @include mediumText(30px, 42px);

  @include respond-to(512px) {
    @include mediumText(20px, 28px);
  }
}

.wrapperSlider {
  height: 286px;

  @include respond-to(768px) {
    height: 186px;
  }
}
